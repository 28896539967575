import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { Grid, CircularProgress, Button, TextField, FormHelperText, Link, Typography } from "@material-ui/core";
import { Subtitle3, XSmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
import { images } from "../../assets";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { Footer } from "../common/Footer";
import Axios from "axios";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { tokenValidation } from "../../helper/customizedHooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import base from "../../config";
import { headers } from "../../helper/api";
import { makeStyles } from "@material-ui/core/styles";
import { CommonDialog } from "../common/CommonComponents/Dialog.js";
import { DialogActions, RadioGroup } from "@material-ui/core";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import { StyledFormControl, StyledSelect, StyledRadioGroup } from "../fui/FuiStyles";
import { MenuItem, InputLabel } from "@material-ui/core";
import moment from "moment";
/**
 * Security page
 * @author poornna.a.ganeshan
 * @version 1.0
 */

const ServerReview = (email, idToken) => {

  useEffect(() => {
    let timer;
    if (email && idToken) {
      timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
        };
        apiCall();
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [email, idToken]);

  return (
    <div>
      <div className="mainDiv">
        <Grid className="headerItems" container>
          <Grid item md={3}>
            <img
              alt=""
              src={images.NavLogoImage}
              style={{ float: "left" }}
            />
          </Grid>
          <Grid item md={9} style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <XSmallHeader
              className="recomHeader"
              style={{ textAlign: "left", color: "#2e2e2e", fontWeight: "500", float: "right" }}
            >
              Security
            </XSmallHeader>
          </Grid>
        </Grid>
        <Grid>
          <h3 style={{ fontWeight: "500", display: "flex", justifyContent: "end"}}>Learn about Security disclosure and submitting a vulnerability</h3>
        </Grid>
        <Divider />
      </div>
      <div>
        <br></br>
        <div>
          <Typography>Email Contact: {" "}Inquiries related to this can be sent to the <a href="mailto:ProtectingAccenture@accenture.com">Protecting Accenture</a> mailbox.</Typography>
          <Typography>Submit a vulnerability: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/requests/new" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/requests/new</a></Typography>
          <Typography>Scope and ROE: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090694-Scope-and-ROE" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090694-Scope-and-ROE</a></Typography>
          <Typography>Acknowledgments: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/articles/360040573233-Acknowledgments" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/articles/360040573233-Acknowledgments</a></Typography>
          <Typography>Terms of Service: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090874-ResponsibleDisclosure-com-s-Terms-of-Service" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090874-ResponsibleDisclosure-com-s-Terms-of-Service</a></Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
});

export default connect(MSTP)(withRouter(ServerReview));