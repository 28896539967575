import React, { useEffect } from "react";
// import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

const Maindiv = styled.div`
  -webkit-column-count: 2;
  -moz-column-count: 2;
  display: flex;
`;

//Here starts the new Login Method using AWS AUTH
const redirectToLogin = () => {

  if (window.location.pathname.includes("auth2") || window.location.pathname.includes("security.txt")) {
    let url = process.env.REACT_APP_SECURITY_PAGE_URL;
    return window.location.replace(url);
  } else {
    let url = process.env.REACT_APP_LOGIN_URL;
    return window.location.replace(url);
  }
};

/**
 * The AuthPage.jsx component features a drawer which contains the users details.
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: Routes.js
 */

const Login = ({ saveTokens, getUserDetails, name = "login", ...props }) => {
  useEffect(() => {
    redirectToLogin();
  }, []);

  return (
    <Maindiv>
      <div>Redirecting...</div>
    </Maindiv>
  );
};

Login.defaultProps = {
  name: "example.one",
};

Login.propTypes = {
  /** Name of the user */
  name: PropTypes.string,
  /** Function for save tokens*/
  saveTokens: PropTypes.func,
  /** Function for get user details */
  getUserDetails: PropTypes.func,
};

// const mapStateToProps = (state, ownProps) => {
//   const auth = state.auth;
//   return {
//     isFetching: auth.isFetching,
//     isAuthenticated: auth.isAuthenticated,
//     isError: auth.isError
//   };
// };

export default // connect(
//   mapStateToProps,
//   null)
Login;
