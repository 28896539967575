import React from "react";
import styled from "styled-components";
import theme from "../../theme/theme";
import { Link } from "react-router-dom";
import {
  TextField,
  FormControl,
  Select,
  FormLabel,
  RadioGroup,
  Tooltip,
  Typography,
  ExpansionPanel,
  Paper,
  Button,
  DialogTitle,
  Avatar,
  TableCell,
  ListItem,
  TableRow,
  Divider,
  CircularProgress,
  Dialog,
  Snackbar,
  Tabs,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  Subtitle3,
  Subtitle2,
  LargeSubtitle,
} from "../common/CommonStyles/CommonTypographyStyles";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

export const StyledDatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  &&&&& {
    border-radius: 0px;
    margin-top: 8px;
    margin-bottom: 4px;
    div {
      height: 40px;
    }
    &&&& label.Mui-disabled {
      top: -8px;
    }
    &&&& label.Mui-error {
      top: -8px;
    }
    &&&& label.Mui-error.Mui-focused {
      top: 0px;
    }
    &&&& label.Mui-error.MuiFormLabel-filled {
      top: 0px;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;
export const StyledDefaultTextField = styled(StyledTextField)`
  &&&&& {
    background: ${grey[200]};
  }
`;
export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  align-content: flex-start;
`;
export const StyledFormLabel = styled(FormLabel)`
  margin-left: 15px;
`;
export const StyledRadioGroup = styled(RadioGroup)`
  margin-left: 40px;
`;
export const StyledParagraph = styled.p`
  text-align: left;
  font-size: 13px;
`;
export const StyledParagraphBold = styled(Typography)`
  &&&&& {
    text-align: left;
    font-weight: 600;
    color: "#000000";
    font-weight: 600;
    margin: 1em 0 0.8em 0;
    font-size: 15px;
  }
`;
export const StyledSelect = styled(Select)`
  text-align: left;
`;
export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledArcher = styled.a`
  text-decoration: none;
`;
export const StyledInfoIcon = styled(InfoIcon)`
  color: ${theme.palette.fui.main};
  margin-right: 0.5em;
`;
export const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{
      popper: props.className,
      tooltipPlacementLeft: "tooltipPlacementLeft",
    }}
    {...props}
  />
))`
  & .tooltipPlacementLeft {
    max-width: 160px;
  }
`;
export const NoUnderlineLink = styled(Link)`
  text-decoration: none;
`;
export const LeftColumn = styled.div`
  width: 48%;
`;
export const RightColumn = styled.div`
  width: 48%;
`;
export const DetailsTypography = styled(Typography)`
  text-align: left;
  &&&&& {
    color: "#000000";
    margin: 1em 1em 3em 0em;
  }
`;
export const StyledExpansionPanel = styled(ExpansionPanel)`
  &&&&& {
    width: 100%;
    padding: 1em 2em 0 2em;
    margin: 0 2em 1em 2em;
  }
`;
export const StepOnePaper = styled(Paper)`
  padding: 2em 3em;
  margin: 0 2em;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const BackWrapper = styled.div`
  width: 100%;
  /* margin-left: 2em; */
  display: flex;
  justify-content: flex-start;
  /* padding-bottom: 1%; */
  align-items: center;
`;
export const BackButton = styled(Button)`
  &&&&& {
    color: #000000;
    font-weight: normal;
  }
`;
export const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  &&&&& {
    color: #000000;
  }
`;

export const SuccessTypography = styled(LargeSubtitle)`
  &&&&& {
    color: ${theme.palette.fui.main};
    text-align: center;
    margin: 5em 0 2em 0;
    margin-left: 1em;
  }
`;

export const SuccessSecondLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  align-self: center;
`;
export const OrderResultWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
export const ResultTitleTypography = styled(Subtitle2)`
  &&&&& {
    margin: 15px 1em 0 1em;
    color: "#000000";
    font-weight: 600;
    // font-weight: bold;
  }
`;
export const StyledFileCopyIcon = styled(FileCopyIcon)`
  color: "#000000";
  font-weight: 600;
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2em;
`;
export const FirstLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
export const StyledParagraphTypography = styled(Subtitle3)`
  &&&&& {
    color: "#000000";
  }
`;

export const StyledTypography = styled(Subtitle3)`
  &&&&& {
    color: "#000000";
    fontWeight: 600;
    margin-left: 1em;
  }
`;

export const StyledTypographyHeader = styled(LargeSubtitle)`
  &&&&& {
    color: "#000000";
    margin-top: 1em;
  }
`;

export const StyledTypographyMessage = styled(Subtitle3)`
  &&&&& {
    color: ${red[600]};
  }
`;
export const CountryTypography = styled(Subtitle3)`
  &&&&& {
    margin: 5px 30px;
  }
`;
export const BoldTypography = styled(Subtitle3)`
  &&&&& {
    font-weight: 600;
    margin: 5px 0;
  }
`;
export const StyledDialogTitle = styled(DialogTitle)`
  &&&&& {
    background: ${theme.palette.fui.main};
    h6 {
      color: #fff;
    }
  }
`;
export const AlertContent = styled.div`
  margin: 15px 0 0 0;
`;
export const AlertButton = styled(Button)`
  &&&&& {
    color: ${theme.palette.fui.main};
    border-color: ${theme.palette.fui.main};
    margin: 0 25px 10px 0;
  }
`;
export const FinalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;
export const IdTypography = styled(Typography)`
  &&&&& {
    margin: 15px 1em 0 0;
  }
`;
export const OkButton = styled(Button)`
  &&&&& {
    color: #fff;
    background: ${theme.palette.fui.main};
    width: 25%;
    align-self: center;
    margin-top: 3em;
  }
`;
export const StyledAvatar = styled(Avatar)`
  &&&&& {
    background: ${theme.palette.fui.main};
  }
`;
export const StyledPaper = styled(Paper)`
  height: auto;
  /* max-height: 600px; */
`;
export const StyledSearchIcon = styled(SearchIcon)`
  color: "#000000";
  font-weight: 500;
`;
export const TableFirstRow = styled(TableCell)`
  &&&&& {
    background: ${grey[200]};
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;
export const StyledColorButton = styled(Button)`
  &&&&& {
    color: ${theme.palette.fui.main};
    border-color: ${theme.palette.fui.main};
    text-transform: none;
  }
`;
export const StyledTableFirstRow = styled(TableCell)`
  &&&&& {
    background: ${grey[200]};
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;

export const PlatformTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const PlatformWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  /* margin-bottom: 1.5em; */
`;
export const PlatformDetailsWrapper = styled.div`
  margin: 10px 0 0 0;
`;
export const PlatformDetailsButton = styled(Button)`
  &&&&& {
    color: ${theme.palette.fui.main};
    border-color: ${theme.palette.fui.main};
    margin: 0 25px 10px 0;
  }
`;
export const StyledSearchBarPlatforms = styled(TextField)`
  &&&&& {
    width: 25em;

    background-color: #f6f6f6;
    border-radius: 0px;
    padding: 0.75rem;
    > div {
      color: #222;
      padding: 0;
      &::before,
      &::after,
      &:hover {
        border: 0;
      }
      > input {
        padding: 0;
      }
    }
  }
`;

export const TypographyWrapper = styled(Typography)`
  padding: 2em 0;
  width: 100%;
`;
export const LeftAlignTypography = styled(StyledTypography)`
  text-align: left;
  padding-left: 3.5em;
  padding-top: 1em;
`;
export const OutlineButton = styled(Button)`
  &&&&& {
    color: ${theme.palette.fui.main};
    border-color: ${theme.palette.fui.main};
  }
  width: 180px;
`;
export const FilledButton = styled(Button)`
  width: 180px;
  &&&&& {
    margin-left: 1em;
  }
`;
export const Hint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
`;
export const StyledTabs = styled(Tabs)`
  &&& {
    margin-bottom: ${(props) => (props.margin ? props.margin : "")};
  }
`;
export const SingleCard = styled.div`
  overflow: hidden;
  width: 35%;
  min-width: 515px;
  max-width: 250px;
  height: 22em;
  margin: 0.5em 1.5em;
  box-shadow: "unset",
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 2250px) {
    height: 18em;
    max-width: 422px;
    min-width: 0px;
  }
  @media (max-width: 1920px) {
    height: 15em;
    max-width: 350px;
    min-width: 0px;
  }
  @media (max-width: 1560px) {
    height: 13em;
    max-width: 303px;
    min-width: 0px;
  }
  @media (max-width: 1360px) {
    height: 9em;
    max-width: 210px;
    min-width: 0px;
  }
  @media (max-width: 1150px) {
    height: 7em;
    max-width: 163px;
    min-width: 0px;
  }
`;

export const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
`;
export const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const TypeName = styled(StyledTypography)`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  color: black;
  margin: 1em;
`;
export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  width: 15em;
  margin-left: 1.5em;
`;
export const BoldTableCell = styled(TableCell)`
  &&&&& {
    font-weight: 600;
  }
`;
export const TableWrapper = styled.div`
  margin: 0 5% 0 5%;
  /* margin: 2em 5% 2em 5%; */
`;
export const StepperTableWrapper = styled.div`
  width: 96%;
  margin: 0 2em 0 2em;
`;
export const HoverTableRow = styled(TableRow)`
  &:hover {
    background: ${grey[100]};
  }
`;
export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1em 5em;
`;
export const DetailTableWrapper = styled.div`
  max-height: 450px;
  min-width: 400px;
`;

export const TopRadioWrapper = styled(RadioWrapper)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const TextHelperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
`;

export const TextHelperTypography = styled(Typography)`
  &&&&& {
    font-size: 15px;
    color: "#000000";
    font-weight: 500;
    margin-left: 1em;
  }
`;
export const ConfigurationFormLabel = styled(FormLabel)`
  font-weight: 500 !important;
  margin-top: 0.8em;
  &&&&& {
    color: ${theme.palette.fui.main};
  }
`;
export const LongDivider = styled(Divider)`
  &&&&& {
    width: 100%;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
  }
`;

export const ConfigurationWrapper = styled.div`
  width: 100%;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const LicensesStatusWrapper = styled.div`
  padding-left: 1em;
`;

export const StatusIconWrapper = styled.div`
  margin-top: -2px;
  margin-right: 5px;
`;

export const OrangeCircularProgress = styled(CircularProgress)`
  &&&&& {
    color: ${orange[900]};
  }
`;

export const BlueCircularProgress = styled(CircularProgress)`
  &&&&& {
    color: ${blue[800]};
  }
`;

export const PlacedCircularProgress = styled(CircularProgress)`
  &&&&& {
    color: "#000000";
    font-weight: 500;
  }
`;

export const MaxWidthDialog = styled(Dialog)`
  &&&&& {
    & > div > div {
      max-width: 1500px;
    }
  }
`;

export const NoInstalledAppWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2em 0 0 0;
`;

export const StyledSpan = styled.span`
  &&&&& {
    color: #fff;
  }
`;

export const StyledSnackbar = styled(Snackbar)`
  & > div {
    background: #B10B02;
  }
  &&&&& {
    margin-bottom: 4.5em;
  }
`;

export const NameIconWrapper = styled.div`
  margin-top: 0.5em;
`;

export const ButtonWrapperFromSsh = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
`;

export const FinalWrapperFromSsh = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 8em 0 0 0; */
`;

export const ToAccessTypography = styled(Typography)`
  &&&&& {
    color: ${theme.palette.fui.main};
    text-align: center;
    margin: 0.5em 0 0 0;
    font-size: 140%;
    margin-left: 1em;
  }
`;

export const ToAccessButtonIndustry = styled(Button)`
  &&&&& {
    color: #fff;
    background: ${theme.palette.fui.main};
    width: 300px;
    align-self: center;
    margin: 2em 0 5em 0.25em;
  }
`;
