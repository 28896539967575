const path = require("path");
require("dotenv").config();

var dev = process.env.NODE_ENV === "development";
const cloudRootNew = process.env.REACT_APP_CLOUD_ROOT;
const newBaseUrl = process.env.REACT_APP_API_URL;
var base = {
  app: {
    root: path.normalize(path.join(__dirname)),
    env: dev,
    images: process.env.PUBLIC_URL + "/images",
    redirectName: "mycloud",
  },
  urls: {
    apis: {
      auth: `${cloudRootNew}auth/token`,
      securityAuth: `${cloudRootNew}auth2/token`,
      sshNew: `${newBaseUrl}ssh-management/`,
      doc: `${newBaseUrl}doc`,
    },
  },
};

module.exports = base;
