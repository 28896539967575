import React, {
  Fragment,
  useEffect,
  // , useState
} from "react";
import Navbar from "../CommonNavbar/CommonNavbar";
import {
  LoyoutWrapperContent,
  LayoutContentWrapper,
} from "./StyledCommonLayout";


import "react-toastify/dist/ReactToastify.css";
import { tokenValidation } from "../../../helper/customizedHooks";

const CommonLayout = (props) => {
  const {
    email,
    idToken,
    children,
    history,
  } = props;

  /**
   * Common Layout of the Page
   * @author shilpa.adikeshava
   * @version 1.0
   * @see Used in component: routes.js
   */

  useEffect(() => {
    setInterval(() => {
      tokenValidation();
    }, 4000);
  }, []);

  return (
    <div>
      {
        history.location.pathname.includes("security.txt") ?
          <Fragment>
            <LoyoutWrapperContent>
              <LayoutContentWrapper style={{ height: "90vh", paddingTop: "2em", paddingLeft: "3.5em" }}>
                {children}
              </LayoutContentWrapper>
            </LoyoutWrapperContent>
          </Fragment> :
          <Fragment>
            <Navbar
              screenName="SSH Management"
            />
            <LoyoutWrapperContent>
              <LayoutContentWrapper style={{ height: "90vh", paddingTop: "2em", paddingLeft: "3.5em" }}>
                {children}
              </LayoutContentWrapper>
            </LoyoutWrapperContent>
          </Fragment>
      }

    </div>
  );
};


export default CommonLayout;